/* eslint-disable react/no-unescaped-entities */
import { Tooltip } from "react-tippy";
import { formatTerpeneList } from "util/common";
import { COUNTRIES } from "util/countryListIcons";
import { StoreDiscountsList } from "components/shop/ShopDiscountsList";
import StrainList from "./StrainList";
import SimilarProductsHiddenRow from "./SimilarProductsHiddenRow";
import { PRODUCT_PACKAGE_HEB_TRANSLATION } from "util/types";
import { GENETIC_MATCH } from "util/types";
import InfoRow from "components/hidden-row/InfoRow";
import HiddenRowHeader from "components/hidden-row/HiddenRowHeader";

export function ProductsTableHiddenRow({
	row = {},
	withCartButton = false,
	isWidget = false,
	isSingleStore = false,
	tabletMode = false,
	showSimilarProducts = false,
	setModalViewProduct = () => {},
	customActionButton,
}) {
	const {
		biz_url: bizUrl,
		catalog_price: catalogPrice,
		batch,
		category,
		description,
		eng_name: engName,
		family,
		from_percent_cbd,
		from_percent_thc,
		from_price: fromPrice,
		grow_type_name: growTypeName,
		grow_type: growType,
		heb_name: hebName,
		id,
		images,
		main_img_url: mainImgUrl,
		manufacturer_biz_url: manufacturerBizUrl,
		manufacturer_heb_name: manufacturerHebName,
		manufacturer_id: manufacturerId,
		manufacturer_series_biz_url: manufacturerSeriesBizUrl,
		manufacturer_series_id: manufacturerSeriesId,
		manufacturer_series_heb_name: manufacturerSeriesHebName,
		marketer_biz_url: marketerBizUrl,
		marketer_heb_name: marketerHebName,
		marketer_id: marketerId,
		origin_country: originCountry,
		packaging_options: packagingOptions = [],
		parent_strains_heb_name: parentStrainsHebName,
		parents,
		parents_second: parentsSecond,
		product_id,
		product_type: productType,
		quantity,
		series_biz_url: seriesBizUrl,
		series_id: seriesId,
		series_heb_name: seriesHebName,
		store_id,
		storeId: storeIdProp,
		store_percent_cbd,
		store_percent_thc,
		store_price: storePrice,
		store_product_date: storeProductDateProp,
		store_product_id: storeProductId,
		store_product_note: storeProductNote,
		strain_biz_url: strainBizUrl,
		strain_heb_name: strainHebName,
		strain_id: strainId,
		symbols,
		terpenes_concentration: terpenesConcentration,
		terpenes,
		to_percent_cbd: toPercentCbd,
		to_percent_thc: toPercentThc,
		to_price: toPrice,
		genetic_match: geneticMatch,
		is_discount_exists: isDiscountExists,
	} = row;

	const productId = product_id || id;
	const storeId = store_id || storeIdProp;

	const getGrowTypeLink = (id, isBiz) => {
		// We need this this way because we dont have table on db for grow types, otherwise we could get links from there
		return isBiz
			? `https://cannabiz.co.il/grow/${id === "mixed" ? "combined" : id}`
			: `/products/?filters=grow_type^in^${id}`;
	};

	const country = COUNTRIES.find(item => item.code === originCountry);

	const productInfo = [
		{ label: "הערת בית מרקחת", content: storeProductNote },
		{
			label: "מגדל",
			content: manufacturerHebName,
			href: `/manufacturers/${manufacturerId}`,
			bizUrl: manufacturerBizUrl,
		},
		{
			label: "משווק",
			content: marketerHebName,
			href: `/marketers/${marketerId}`,
			bizUrl: marketerBizUrl,
		},
		{
			label: "סדרה",
			content: seriesHebName,
			href: `/products/?filters=series_id^in^${seriesId}`,
			bizUrl: seriesBizUrl,
		},
		{
			label: "מותג",
			content: manufacturerSeriesHebName,
			href: `/products/?filters=manufacturer_series_id^in^${manufacturerSeriesId}`,
			bizUrl: manufacturerSeriesBizUrl,
		},
		{
			label: "ארץ ייצור",
			content: country.heb_name,
			href: `/products/?filters=origin_country^in^${country.code}`,
		},
		{
			label: "זן מקור",
			content: strainHebName,
			href: `/strains/${strainId}`,
			bizUrl: strainBizUrl,
		},
		{ label: "שיוך גנטי", content: GENETIC_MATCH[geneticMatch] },
		{
			label: "הורה #1",
			content: !!parents && (
				<div className="flex gap-2 flex-wrap items-center">
					<StrainList
						parents={parents}
						tabletMode={tabletMode}
						disableLinks={isWidget && isSingleStore}
					/>
				</div>
			),
		},
		{
			label: "הורה #2",
			content: !!parentsSecond && (
				<div className="flex gap-2 flex-wrap items-center">
					<StrainList
						parents={parentsSecond}
						tabletMode={tabletMode}
						disableLinks={(isWidget && isSingleStore) || tabletMode}
					/>
				</div>
			),
		},
		{
			label: "סוג גידול",
			content: growTypeName,
			href: getGrowTypeLink(growType, false),
			bizUrl: getGrowTypeLink(growType, true),
		},
		{
			label: "ריכוז טרפנים",
			content: terpenesConcentration && `${terpenesConcentration}%`,
		},
		{ label: "טרפנים", content: formatTerpeneList(terpenes) },
		{
			label: "סמלילים",
			content: !!symbols && !!symbols.length && (
				<div className="flex flex-wrap gap-1">
					{symbols
						?.sort((a, b) => a.description.localeCompare(b.description))
						.map((parent, index) => {
							return (
								<Tooltip
									key={index}
									title={parent?.description}
									placement="top"
									arrow
								>
									<img
										key={index}
										className="w-5 h-5 md:w-8 md:h-8 lg:w-10 lg:h-10 m-0.5 rounded"
										src={parent?.img_url}
										alt={parent?.description}
										title={parent?.description}
									/>
								</Tooltip>
							);
						})}
				</div>
			),
		},
		{
			label: "סוגי אריזה",
			content: !!packagingOptions?.length && (
				<div className="flex gap-1">
					{packagingOptions.map((t, idx) => (
						<div key={idx} className="flex items-center gap-0.5">
							{PRODUCT_PACKAGE_HEB_TRANSLATION[t].icon}
							<span>{PRODUCT_PACKAGE_HEB_TRANSLATION[t].text}</span>
						</div>
					))}
				</div>
			),
		},
	];

	return (
		<div className="p-2">
			<div className="flex flex-col mt-4 gap-2">
				<HiddenRowHeader
					row={row}
					isWidget={isWidget}
					isSingleStore={isSingleStore}
					withCartButton={withCartButton}
					customActionButton={customActionButton}
					tabletMode={tabletMode}
					storeId={storeId}
				/>
				{isSingleStore && (
					<StoreDiscountsList
						storeId={storeId}
						productId={productId}
						withInitDiscount={false}
						isDiscountExists={isDiscountExists}
					/>
				)}
				<div className="grid  grid-cols-[auto,1fr] gap-x-2 gap-y-1">
					{productInfo.map((info, index) => {
						return (
							<InfoRow
								label={info?.label}
								content={info?.content}
								href={info?.href}
								bizUrl={info?.bizUrl}
								isSingleStore={isSingleStore}
								key={index}
							/>
						);
					})}
				</div>

				{!!description && <div>{description}</div>}
				{showSimilarProducts && (
					<SimilarProductsHiddenRow
						productId={productId}
						storeId={storeId}
						setModalViewProduct={setModalViewProduct}
					/>
				)}

				{isSingleStore && !tabletMode && (
					<div className="text-[11px] mt-2 text-gray-900">
						*המידע הוזן ובאחריות בית המרקחת בלבד. מצאת טעות?{" "}
						<a
							className="underline text-blue-400"
							href="https://docs.google.com/forms/d/e/1FAIpQLSdQNHnXCBlc-gka0p-VYcwbP-z3j9Dc41s8VcJFNgGYOl2PjQ/viewform"
							target="_blank"
							rel="noreferrer"
						>
							דווח כאן
						</a>
					</div>
				)}
			</div>
		</div>
	);
}
