import { ProductTypeUI, VARIANT } from "components/products/ProductTypeUI";
import React, { useCallback, useMemo } from "react";
import { getProductQuickFilters } from "util/commonFilters";
import { listProductTypes } from "util/types";

const QuickFilters = ({ cache, tbl, isSingleStore = false }) => {
	const quickFilters = useMemo(() => {
		if (isSingleStore) {
			return getProductQuickFilters(cache).map(f => ({
				...f,
				property: `product__${f.property}`,
			}));
		} else {
			return getProductQuickFilters(cache);
		}
	}, [cache, isSingleStore]);

	const isFilterActive = useCallback(
		value => {
			const valArray = tbl.filters.flatMap(obj => obj.value);
			if (valArray.includes(value)) return true;
			return false;
		},
		[tbl.filters],
	);

	const handleQuickFiltersOnClick = (value, filters) => {
		const labels = filters.options
			.filter(optionsObj => {
				if (value === "T20/C4") {
					return optionsObj.value === value || optionsObj.value === "T22/C4";
				} else if (value === "T22/C4") {
					return optionsObj.value === value || optionsObj.value === "T20/C4";
				} else {
					return optionsObj.value === value;
				}
			})
			.map(opt => opt.label);
		let isActive = isFilterActive(value);
		const values =
			value === "T20/C4"
				? [value, "T22/C4"]
				: value === "T22/C4"
				? [value, "T20/C4"]
				: [value];
		if (isActive) {
			//remove filter

			tbl.removeFilter({ property: filters.property, value: values, labels });
		} else {
			//add filter
			tbl.addAdditionalFilters({
				property: filters.property,
				value: values,
				condition: "in",
				labels,
			});
		}
	};

	return (
		<div className="px-1 pt-2">
			<div className="flex overflow-x-auto pb-2 gap-1 custom-scroll">
				<div className="relative w-fit h-[53px] md:h-[70px] flex items-end px-1">
					<span className="absolute z-10 left-0 right-0 w-fit mx-auto text-center top-0 text-jane-400 bg-white px-1 text-[12px]">
						{quickFilters[0].placeholder}
					</span>
					<div className="absolute w-[100%] mx-auto border border-b-0 h-2.5 top-2.5 border-gray-300 self-center right-0" />

					<div className="mt-3 flex items-center gap-1.5 w-fit z-30 justify-center">
						{quickFilters[0].options
							.filter(({ value }) => value !== "$all")
							.map(({ value }) => (
								<div
									key={value}
									className="cursor-pointer"
									onClick={() =>
										handleQuickFiltersOnClick(value, quickFilters[0])
									}
								>
									<ProductTypeUI
										productType={listProductTypes.find(
											productType => productType.label === value,
										)}
										variant={VARIANT.BUTTON}
										isActive={isFilterActive(value)}
									/>
								</div>
							))}
					</div>
				</div>

				<div className="relative w-fit h-[53px] md:h-[70px] flex items-end px-1">
					<div className="absolute w-[100%] mx-auto border border-b-0 h-2.5 top-2.5 border-gray-300 self-center right-0" />
					<span className="absolute left-0 right-0 w-fit mx-auto text-center top-0 text-jane-400 bg-white px-1 text-[12px]">
						{quickFilters[2].placeholder}
					</span>
					<div className="mt-3 flex items-center gap-1.5 w-fit justify-center">
						{quickFilters[2].options
							.filter(({ value }) => value !== "$all")
							.map(({ value }) => (
								<div
									key={value}
									className="cursor-pointer"
									onClick={() =>
										handleQuickFiltersOnClick(value, quickFilters[2])
									}
								>
									<ProductTypeUI
										productType={listProductTypes.find(productType => {
											return productType.label === value;
										})}
										variant={VARIANT.BUTTON}
										isActive={isFilterActive(value)}
									/>
								</div>
							))}
					</div>
				</div>

				<div className="relative w-fit h-[53px] md:h-[70px] flex items-end px-1">
					<div className="absolute w-[100%] mx-auto border border-b-0 h-2.5 top-2.5 border-gray-300 self-center right-0" />
					<span className="absolute left-0 right-0 w-fit mx-auto text-center top-0 text-jane-400 bg-white px-1 text-[12px]">
						{quickFilters[1].placeholder}
					</span>
					<div className="mt-3 flex items-center gap-1.5 w-fit justify-center">
						{quickFilters[1].options
							.filter(({ value }) => value !== "$all")
							.map(({ value }) => (
								<div
									key={value}
									className="cursor-pointer"
									onClick={() =>
										handleQuickFiltersOnClick(value, quickFilters[1])
									}
								>
									<ProductTypeUI
										productType={listProductTypes.find(
											productType => productType.label === value,
										)}
										variant={VARIANT.BUTTON}
										isActive={isFilterActive(value)}
									/>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuickFilters;
