import { useIsInIframe } from "components/hooks/useIsInIframe";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const LinkedContent = ({
	children,
	isSingleStore,
	href,
	bizUrl,
	underline = true,
}) => {
	const isInIframe = useIsInIframe();
	const { route } = useRouter();
	if (isSingleStore && !isInIframe) {
		return children;
	}
	if (isInIframe && !!bizUrl) {
		return (
			<a
				href={bizUrl}
				target="_blank"
				rel="noopener noreferrer"
				className={`cursor-pointer ${underline && "underline"}`}
			>
				{children}
			</a>
		);
	}
	if (!isInIframe && !!href) {
		const [pathname] = href.replace(/\//g, "").split("?");
		const cleanRoute = route.replace(/\//g, "");
		// If the link is to the same page, don't use Link
		if (pathname === cleanRoute) {
			return (
				<a
					href={href}
					rel="noopener noreferrer"
					className={`cursor-pointer ${underline && "underline"}`}
				>
					{children}
				</a>
			);
		} else {
			return (
				<Link href={href}>
					<a
						rel="noopener noreferrer"
						className={`cursor-pointer ${underline && "underline"}`}
					>
						{children}
					</a>
				</Link>
			);
		}
	}
	return children;
};

export default LinkedContent;
