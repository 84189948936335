import React from "react";
import LinkedContent from "./LinkedContent";

const InfoRow = ({ content, label, href, bizUrl, isSingleStore = false }) => {
	if (!content) {
		return;
	}
	return (
		<>
			<span className="font-semibold">{label}</span>
			<span>
				<LinkedContent
					href={href}
					bizUrl={bizUrl}
					isSingleStore={isSingleStore}
				>
					{content}
				</LinkedContent>
			</span>
		</>
	);
};

export default InfoRow;
