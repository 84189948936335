import React from "react";

const HiddenRowProductName = ({ hebName, engName }) => {
	return (
		<div className="flex flex-col font-bold items-start text-jane-800 md:flex-row md:items-center md:gap-1">
			<span className=" text-xl">{hebName}</span>
			<span>{"(" + engName + ")"}</span>
		</div>
	);
};
export default HiddenRowProductName;
