import { useApi } from "components/hooks/useApi";
import React, { useEffect, useState } from "react";
import SimilarProductsSwiper from "./SimilarProductsSwiper";

function SimilarProductsHiddenRow({
	productId,
	storeId = null,
	setModalViewProduct = () => {},
}) {
	const api = useApi();
	const [similarProducts, setSimilarProducts] = useState([]);
	const isSingleStore = !!storeId;

	useEffect(() => {
		if (!productId) return;
		loadSimilarProducts();
	}, [productId]);

	const loadSimilarProducts = async () => {
		if (isSingleStore) {
			// Store page
			const res = await api.getSimilarStoreProducts(productId, storeId);
			if (res.ok) {
				setSimilarProducts(
					res.data.items.map(item => ({ ...item, ...item.product })),
				);
			}
		} else {
			// Global tables
			const res = await api.getSimilarProducts(productId);
			if (res.ok) {
				setSimilarProducts(res.data.items);
			}
		}
	};

	if (!productId || !similarProducts?.length) return;

	return (
		<div className="relative w-full h-[155px] overflow-hidden">
			<p className="font-semibold">מוצרים עם גנטיקה זהה במלאי:</p>
			<div className="absolute w-full">
				<SimilarProductsSwiper
					products={similarProducts}
					storeId={storeId}
					setModalViewProduct={setModalViewProduct}
				/>
			</div>
		</div>
	);
}

export default SimilarProductsHiddenRow;
